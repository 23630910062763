exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-basalt-stone-js": () => import("./../../../src/pages/basalt-stone.js" /* webpackChunkName: "component---src-pages-basalt-stone-js" */),
  "component---src-pages-blog-category-js": () => import("./../../../src/pages/blog/category.js" /* webpackChunkName: "component---src-pages-blog-category-js" */),
  "component---src-pages-blog-category-tarpaulin-js": () => import("./../../../src/pages/blog/category/tarpaulin.js" /* webpackChunkName: "component---src-pages-blog-category-tarpaulin-js" */),
  "component---src-pages-blog-comp-1-js": () => import("./../../../src/pages/blogComp1.js" /* webpackChunkName: "component---src-pages-blog-comp-1-js" */),
  "component---src-pages-blog-comp-2-js": () => import("./../../../src/pages/blogComp2.js" /* webpackChunkName: "component---src-pages-blog-comp-2-js" */),
  "component---src-pages-blog-comp-2-related-js": () => import("./../../../src/pages/blogComp2Related.js" /* webpackChunkName: "component---src-pages-blog-comp-2-related-js" */),
  "component---src-pages-blog-comp-3-js": () => import("./../../../src/pages/blogComp3.js" /* webpackChunkName: "component---src-pages-blog-comp-3-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-tarp-js": () => import("./../../../src/pages/blogTarp.js" /* webpackChunkName: "component---src-pages-blog-tarp-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-cnc-work-and-handicraft-design-js": () => import("./../../../src/pages/cnc-work-and-handicraft-design.js" /* webpackChunkName: "component---src-pages-cnc-work-and-handicraft-design-js" */),
  "component---src-pages-cobble-stone-js": () => import("./../../../src/pages/cobble-stone.js" /* webpackChunkName: "component---src-pages-cobble-stone-js" */),
  "component---src-pages-consultancy-js": () => import("./../../../src/pages/consultancy.js" /* webpackChunkName: "component---src-pages-consultancy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-door-frame-or-chaukhat-js": () => import("./../../../src/pages/door-frame-or-chaukhat.js" /* webpackChunkName: "component---src-pages-door-frame-or-chaukhat-js" */),
  "component---src-pages-faq-hdpe-2-js": () => import("./../../../src/pages/faqHdpe2.js" /* webpackChunkName: "component---src-pages-faq-hdpe-2-js" */),
  "component---src-pages-faq-hdpe-js": () => import("./../../../src/pages/faqHdpe.js" /* webpackChunkName: "component---src-pages-faq-hdpe-js" */),
  "component---src-pages-faq-shade-net-2-js": () => import("./../../../src/pages/faqShadeNet2.js" /* webpackChunkName: "component---src-pages-faq-shade-net-2-js" */),
  "component---src-pages-faq-shade-net-js": () => import("./../../../src/pages/faqShadeNet.js" /* webpackChunkName: "component---src-pages-faq-shade-net-js" */),
  "component---src-pages-faq-tarp-js": () => import("./../../../src/pages/faqTarp.js" /* webpackChunkName: "component---src-pages-faq-tarp-js" */),
  "component---src-pages-frequently-asked-questions-category-js": () => import("./../../../src/pages/frequently-asked-questions/category.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-js" */),
  "component---src-pages-frequently-asked-questions-category-tarpaulin-js": () => import("./../../../src/pages/frequently-asked-questions/category/tarpaulin.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-tarpaulin-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-granite-js": () => import("./../../../src/pages/granite.js" /* webpackChunkName: "component---src-pages-granite-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-kota-stone-js": () => import("./../../../src/pages/kota-stone.js" /* webpackChunkName: "component---src-pages-kota-stone-js" */),
  "component---src-pages-marble-js": () => import("./../../../src/pages/marble.js" /* webpackChunkName: "component---src-pages-marble-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-plastic-tarpaulin-js": () => import("./../../../src/pages/plastic-tarpaulin.js" /* webpackChunkName: "component---src-pages-plastic-tarpaulin-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-marble-italian-romero-js": () => import("./../../../src/pages/products/marble/italian-romero.js" /* webpackChunkName: "component---src-pages-products-marble-italian-romero-js" */),
  "component---src-pages-sand-stone-js": () => import("./../../../src/pages/sand-stone.js" /* webpackChunkName: "component---src-pages-sand-stone-js" */),
  "component---src-pages-tarpaulin-manufacturer-in-ahmedabad-js": () => import("./../../../src/pages/tarpaulin-manufacturer-in-ahmedabad.js" /* webpackChunkName: "component---src-pages-tarpaulin-manufacturer-in-ahmedabad-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-waterproof-tirpal-js": () => import("./../../../src/pages/waterproof-tirpal.js" /* webpackChunkName: "component---src-pages-waterproof-tirpal-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-frequently-asked-questions-js": () => import("./../../../src/templates/frequently-asked-questions.js" /* webpackChunkName: "component---src-templates-frequently-asked-questions-js" */)
}

