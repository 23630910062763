module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rajasthtan Stones","short_name":"Rajasthtan Stones","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","crossOrigin":"use-credentials","icon":"static/rajasthan-icon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/contact-us/"],"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"image-cache","expiration":{"maxEntries":100,"maxAgeSeconds":604800,"purgeOnQuotaError":true},"plugins":[{"handler":"CacheableResponsePlugin","options":{"statuses":[0,200],"maxFileSizeToCacheInBytes":52428800}}]}},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"google-fonts-stylesheets"},"plugins":[{"handler":"CacheableResponsePlugin","options":{"statuses":[0,200]}}]},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"static-resources","expiration":{"maxAgeSeconds":2592000}}},{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"third-party","expiration":{"maxAgeSeconds":86400}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.rajasthanstones.in","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1054017085806824"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"defer":true,"trackingIds":["G-FRR0V6LX9V","AW-376873750","GTM-WBTWQ9BB"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
